import {useContext} from "react";
import {Card, ConfigProvider, List, message, Space, Spin} from "antd";
import {Link, useParams} from "react-router-dom";
import {WSContext} from "../providers/WSProvider";
import Account from "./settings/Account";
import Groups from "./settings/Groups";
import Integrations from "./settings/Integrations";
import Security from "./settings/Security";

const { ConfigContext } = ConfigProvider;

export default function Settings() {
  let { settingsType, token } = useParams();
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const sections = {
    account: {hidden: false},
    security: {hidden: false},
    verify_email: {hidden: true},
    groups: {hidden: false},
    integrations: {hidden: false},
    // 'receipts',
  };

  if (!(settingsType in sections)) {
    window.location.href = '/404';
  }

  let content;
  switch (settingsType) {
    case 'verify_email':
      content = (
        <Card
          title={configContext.locale.Settings.sections.verify_email}
          style={{ maxWidth: 600, width: 600 }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin size='large' />
          </div>
        </Card>
      );
      wsContext.sendRequest(
        wsContext.prepareRequest('email.verify_email', {token}),
      ).then(
        data => {
          console.log(data);
          window.location.href = '/settings/account/';
        },
        error => message.error(error.message),
      )
      break;
    case 'security':
      content = (<Security />);
      break;
    case 'transactions':
      content = (<div>trans</div>);
      break;
    case 'groups':
      content = (<Groups />);
      break;
    case 'integrations':
      content = (<Integrations />);
      break;
    default:
      content = (<Account />);
  }

  return (
    <Space direction='horizontal' style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
      <Card>
        <List
          size='large'
          dataSource={Object.keys(sections).filter(item => !sections[item]['hidden'])}
          renderItem={item => (
            <List.Item>
              <Link to={`/settings/${item}/`}>{configContext.locale.Settings.sections[item]}</Link>
            </List.Item>
          )}
        />
      </Card>
      {content}
    </Space>
  )
}
