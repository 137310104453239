import React, {useContext} from 'react';
import {AuthContext} from "../providers/AuthProvider";
import {useNavigate}  from "react-router-dom";
import {Login} from "../pages";
import {ConfigProvider, message, Spin} from "antd";

const { ConfigContext } = ConfigProvider;

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (authContext.user === null && !localStorage.getItem('token')) {
      navigate('/login/');
      message.error(configContext.locale.Phrases.protected_error);
    }
  }, [authContext.user, navigate, configContext]);

  if (!authContext.user) {
    if (!localStorage.getItem('token')) {
      return (<Login/>);
    }

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin size='large' />
      </div>
    );
  }

  return (<Component {...rest} />)
}

export default ProtectedRoute;
