// import enGB from 'antd/lib/locale/en_GB';
import enUS from 'antd/lib/locale/en_US'; // US locale would definitely include more
import ukUA from 'antd/lib/locale/uk_UA';

import en from '../locales/en.json';
import ua from '../locales/ua.json';

const merge = (current, updates) => {
  for (let key of Object.keys(updates)) {
    if (!current.hasOwnProperty(key) || typeof updates[key] !== 'object') {
      current[key] = updates[key];
    } else {
      merge(current[key], updates[key]);
    }
  }
  return current;
}

const TransactionType = {
  EXPENSE: 'EXPENSE',
  INCOME: 'INCOME',
}

const TransactionsView = {
  CHART: 'chart',
  TABLE: 'table',
}

const supportedLocales = {
  en_GB: {...merge(enUS, en), language: 'en_GB'},
  uk_UA: {...merge(ukUA, ua), language: 'uk_UA'},
};

export {
  supportedLocales,
  TransactionType,
  TransactionsView,
}
