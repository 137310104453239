import {Cell, Pie, PieChart, Tooltip} from "recharts";
import {currency} from "../../utils/formatters";

export default function Chart({data}) {
  const colors = [
    '#9c0000',
    '#331e8a',
    '#b25a1b',
    '#60621e',
    '#1d8c85',
    '#522d8d',
    '#263a0d',
    '#084242',
    '#71a639',
    '#706a44',
    '#5e3b0e',
    '#986a15',
    '#00609c',
    '#51a807',
    '#af2645',
    '#9c0065',
    '#0c2f31',
    // '#9c0000',
    // '#9c0000',
    // '#9c0000',
  ];

  let categories = [];
  let products = [];

  for (let key in data['categorized']) {
    categories.push({
      category: key,
      value: parseFloat(data['categorized'][key].total),
    });
  }
  let bigCategories = categories.filter(
    row => row['value'] > parseFloat(data['total']) * 0.02
  ).sort(
    (prev, curr) => prev['value'] > curr['value'] ? 1: -1
  );
  let smallCategories = {
    value: 0,
    categories: [],
  };
  if (categories.length !== bigCategories.length) {
    smallCategories = categories.filter(
      row => row['value'] <= parseFloat(data['total']) * 0.02
    ).reduce((prev, curr) => {
      return {
        value: prev['value'] + curr['value'],
        categories: [...prev['categories'], curr['category']],
      }
    }, {
      value: 0,
      categories: [],
    });
  }

  for (let category of bigCategories) {
    let _products = data['categorized'][category.category]['products'].map(product => {
      product['value'] = parseFloat(product['value']);
      return product;
    });

    let bigProducts = _products.filter(
      row => row['value'] > parseFloat(data['total']) * 0.02
    ).sort(
      (prev, curr) => prev['value'] > curr['value'] ? 1: -1
    );
    products.push(...bigProducts);
    if (_products.length !== bigProducts.length) {
      products.push(_products.filter(
        row => row['value'] <= parseFloat(data['total']) * 0.02
      ).reduce((prev, curr) => {
        return {
          value: prev['value'] + curr['value'],
          product: [prev['product'], curr['product']].join(', '),
        }
      }));
    }
  }

  categories = bigCategories;
  if (smallCategories['categories'].length > 0) {
    let smallProducts = [];
    smallCategories['categories'].map(category => {
      smallProducts.push(...data['categorized'][category]['products']);

      return category;
    });
    products.splice(0, 0, smallProducts.reduce((prev, curr) => {
      return {
        value: parseFloat(prev['value']) + parseFloat(curr['value']),
        product: [prev['product'], curr['product']].join(', '),
      }
    }));
    categories.splice(0, 0,{
      category: smallCategories['categories'].join(', '),
      value: products[0]['value'],
    })
  }

  return (
    <PieChart width={window.innerWidth - 100} height={400}>
      <Pie data={categories} dataKey="value" nameKey="category" cx="50%" cy="50%" innerRadius={135} outerRadius={143}>
        {categories.map((_, index) => (
          <Cell key={`cell-${index}`} fill={colors[colors.length - (index % colors.length) - 1]} />
        ))}
        isAnimationActive={false}
      </Pie>
      <Pie
        data={products}
        dataKey="value"
        nameKey="product"
        cx="50%"
        cy="50%"
        innerRadius={65}
        outerRadius={130}
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          name,
          fill
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text
              x={x}
              y={y}
              fill={fill}
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {name} ({currency(value)})
            </text>
          );
        }}
        isAnimationActive={false}
      >
        {products.map((_, index) => (
          // '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip separator=': ' formatter={(value) => currency(value)} />
    </PieChart>
  )
}
