import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useNavigate}  from "react-router-dom";
import {useGoogleLogin, googleLogout} from '@react-oauth/google';
import {message} from "antd";
import {WSContext} from "./WSProvider";
import {supportedLocales} from "../utils/constants";

const AuthContext = createContext({
  user: null,
  settings: null,
  signIn: () => null,
  logout: () => null,
  verifiedSuccessfully: () => null,
});

const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const wsContext = useContext(WSContext);
  const navigate = useNavigate();

  const verifyUser = useCallback(
    (accessToken = null, token = null) => {
      let body;
      if (token !== null) {
        body = {token};
      } else {
        body = {
          access_token: accessToken,
        };
      }

      return wsContext.sendRequest(wsContext.prepareRequest('login', body))
    }, [wsContext],
  );

  const cleanup = () => {
    const language = localStorage.getItem('language');
    localStorage.clear();
    if (language !== undefined) {
      localStorage.setItem('language', language);
    }
    setUser(null);
    setSettings(null);
    window.location.href = '/';
  }

  const logout = () => {
    googleLogout();
    cleanup();
  }

  const verifiedSuccessfully = (data) => {
    const language = localStorage.getItem('language');
    localStorage.clear();
    if (language !== undefined) {
      localStorage.setItem('language', language);
    }
    localStorage.setItem('token', data['token']);
    setSettings(data['settings']);
    setUser(data['user']);
    message.success(supportedLocales[language]?.Messages?.logged_in);
  }

  // TODO: pass gcid as process.env
  const signIn = useGoogleLogin({
    onSuccess: response => {
      verifyUser(response.access_token).then(
        response => {
          verifiedSuccessfully(response);
          navigate('/');
        },
        (error) => {
          console.error(error);
          cleanup();
        }
      );
    },
    onError: error => console.error(error),
    scope: 'email profile'
  })

  useEffect(() => {
    if (!user && localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      verifyUser(null, token).then(
        response => {
          verifiedSuccessfully(response);
        },
        (error) => {
          console.error(error);
          cleanup();
        }
      )
    }
  }, [user, wsContext, verifyUser]);

  const component = (<>{children}</>);

  return (
    <AuthContext.Provider value={{
      user,
      settings,
      signIn,
      logout,
      verifiedSuccessfully,
    }}>
      {component}
    </AuthContext.Provider>
  )
}

export {
  AuthContext,
  AuthProvider,
}
