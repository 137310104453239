import {Select as ASelect} from "antd";

export default function Select({data, placeholder, onChange, selected, allowClear, onSearch}) {
  return (
    <ASelect
      showSearch
      allowClear={allowClear === undefined ? true : allowClear}
      placeholder={placeholder}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={onChange}
      onSearch={onSearch}
      style={{ width: 190 }}
      defaultValue={selected}
      options={data}
    />
  )
}
