const getMemberName = (member, email, you, anonymous) => {
  if (member['email'] === email) {
    return (<strong>{you}</strong>);
  }

  let memberName = '';
  if (member['first_name']) {
    memberName = member['first_name'];
    if (member['last_name']) {
      memberName += ` ${member['last_name']}`;
    }
  } else {
    memberName = anonymous;
  }
  memberName += ` (${member['email']})`;

  return memberName.trim();
}

export {
  getMemberName,
}
