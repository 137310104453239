import {Button, Card, ConfigProvider, DatePicker, Empty, Radio, Space, Table, Typography} from "antd";
import {TransactionType, TransactionsView} from "../utils/constants";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../providers/AuthProvider";
import {WSContext} from "../providers/WSProvider";
import {currency, date, quantity} from "../utils/formatters";
import ActionsButtons from "../components/ActionsButtons";
import {TransactionsChart, TransactionsForm} from "../components/transactions";
import dayjs from 'dayjs';
import {getMemberName} from "../utils/getMemberName";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { ConfigContext } = ConfigProvider;

function Transactions() {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [loaded, setLoaded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [balance, setBalance] = useState(0);
  const [expensesSummary, setExpensesSummary] = useState(0);
  const [incomesSummary, setIncomesSummary] = useState(0);
  const [view, setView] = useState(TransactionsView.CHART);
  const [zeroDate, setZeroDate] = useState(null);
  const [datesRange, setDatesRange] = useState(authContext.settings['transactions_dates']);

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [tableFilters, setTableFilters] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setLoaded(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: configContext.locale.Transactions.table.created_at,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => date(new Date(value), true, configContext.locale.locale),
    },
    {
      title: configContext.locale.Transactions.table.product,
      dataIndex: 'products_name',
      key: 'products_name',
    },
    {
      title: configContext.locale.Transactions.table.price,
      dataIndex: 'price',
      key: 'price',
      render: (value, row) => {
        if (row['children']) {
          return '--';
        }
        return currency(value);
      },
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: configContext.locale.Transactions.table.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, row) => {
        let precision = 3;
        if (row['children']) {
          precision = 0;
        }
        return quantity(value, precision);
      },
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: configContext.locale.Transactions.table.total,
      dataIndex: 'total',
      key: 'total',
      render: (value, row) => {
        return (
          <Text type={row['type'] === TransactionType.INCOME ? 'success' : 'warning'}>
            {currency(value)}
          </Text>
        );
      }
    },
    {
      title: configContext.locale.Transactions.table.actions,
      key: 'id',
      dataIndex: 'id',
      render: (receiptsId, row) => {
        if (row['users_id'] === authContext.user['id']) {
          return (
            <ActionsButtons id={receiptsId} deleteMethod={deleteTransaction} deleteOnly={true} />
          );
        }
      },
      filters: tableFilters,
      filterSearch: false,
      filterMode: 'tree',
      defaultFilteredValue: filters,
    },
  ];

  const disabledDate = (current) => {
    return current && current.valueOf() < dayjs(authContext.settings['min_transaction_date']).startOf('day');
  }
  const changeRange = (dates) => {
    if (dates && dates.length > 0) {
      dates[1] = dates[1].endOf('day');
    }
    setDatesRange(dates);
    setLoaded(false);
  }

  // const editTransaction = (transactionsId) => {
  //   setEditingKey(categoriesId);
  // };
  // const updateCategory = (categoriesId) => {
  //   wsContext.sendRequest(
  //     wsContext.prepareRequest(
  //       'categories.update',
  //       {
  //         id: categoriesId,
  //         ...form.getFieldsValue(),
  //       }
  //     )
  //   ).then(
  //     (data) => {
  //       if (data['category']['updated'] === 1) {
  //         cancel(categoriesId);
  //         setLoaded(false);
  //       } else {
  //         console.error('failed to update');
  //       }
  //     },
  //   )
  // }
  const deleteTransaction = (transactionsId) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'receipts.delete',
        {
          id: transactionsId,
        }
      ),
    ).then(
      () => {
        setLoaded(false);
      },
    );
  }

  const handleTableChange = (pagination, tableFilters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setOffset(pagination.current - 1);
      setLimit(pagination.pageSize);
    } else if (extra.action === 'filter') {
      let _filters = tableFilters['id'] || [];
      let users = [];
      for (let filter of _filters) {
        if (typeof filter === 'number') {
          users.push({users_id: filter});
          continue;
        }

        if (typeof JSON.parse(filter) === 'string') {
          continue;
        }
        users.push(JSON.parse(filter));
      }
      setFilters({users});
    }
    setLoaded(false);
  }

  useEffect(() => {
    setDatesRange(authContext.settings['transactions_dates']);
    if (!loaded) {
      const params = {
        current_dt: new Date(),
        start_dt: datesRange && datesRange[0],
        end_dt: datesRange && datesRange[1],
        filters,
      }
      wsContext.sendRequest(
        wsContext.prepareBatchRequest(
          wsContext.prepareRequest('receipts.get_chart', params),
          wsContext.prepareRequest('receipts.get_balances', params),
          // TODO: optimize chart/table loading (either not both should be done)
          wsContext.prepareRequest('receipts.get_list', {...params, limit, offset}),
          wsContext.prepareRequest('groups.get_list'),
        )
      ).then(
        (response) => {
          if (!response) {
            console.error('empty response', response);
            return;
          }
          let [
            _chartData,
            _balances,
            _receipts,
            _groups,
          ] = response;

          if (_chartData && _chartData['result']) {
            setChartData(_chartData['result']['chart_data']);
          }

          if (_balances && _balances['result'] && _balances['result']['balances'].length > 0) {
            const latestBalance = _balances['result']['balances'].slice(-1)[0];
            setExpensesSummary(latestBalance[TransactionType.EXPENSE.toLowerCase()] || 0);
            setIncomesSummary(latestBalance[TransactionType.INCOME.toLowerCase()] || 0);
            setBalance(latestBalance['balance'] || 0);
            setZeroDate(latestBalance['zero_date'] !== null ? new Date(latestBalance['zero_date']) : null);
          }

          if (_receipts && _receipts['result']) {
            setTransactions(_receipts['result']['receipts'].map(row => {
              row['key'] = row['id'];
              if (row['items']) {
                row['children'] = row['items'].map(item => {
                  item['key'] = item['id'];
                  return item;
                });
              }

              return row;
            }));
          }

          if (_groups && _groups['result']) {
            const f = [
              {
                text: configContext.locale.Transactions.table.your_transactions,
                value: authContext.user['id'],
              },
              ..._groups['result']['groups'].filter(group => group['members'].length > 1).map(group => {
                return {
                  text: group['name'],
                  value: JSON.stringify(group['id']),
                  children: group['members'].filter(
                    member => member['email'] !== authContext.user['email'],
                  ).map(member => {
                    return {
                      text: getMemberName(member, null, null, configContext.locale.Phrases.anonymous),
                      value: JSON.stringify({
                        groups_id: group['id'],
                        users_id: member['id'],
                      }),
                    };
                  }),
                }
              })
            ];
            setTableFilters(f);
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
      setLoaded(true);
    }
  }, [loaded, wsContext, datesRange, filters, limit, offset, authContext, configContext, setDatesRange]);

  const transactionsRepresentation = view === TransactionsView.CHART ? (
    chartData && chartData['total'] ? (
      <TransactionsChart data={chartData} />
    ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
  ) : (
    <Table
      dataSource={transactions}
      columns={columns}
      scroll={{ x: true }}
      onChange={handleTableChange}
      loading={!loaded}
      pagination={{ current: offset + 1, pageSize: limit, showSizeChanger: true }}
    />
  );

  return (
    <>
      <Space style={{ textAlign: 'right' }} wrap={true}>
        <Card title={configContext.locale.Transactions.statistics.income} style={{ minWidth: 200 }}>
          <Text type="success" strong>{currency(incomesSummary)}</Text>
        </Card>
        <Card title={configContext.locale.Transactions.statistics.expenses} style={{ minWidth: 200 }}>
          <Text type="warning" strong>{currency(expensesSummary)}</Text>
        </Card>
        <Card title={configContext.locale.Transactions.statistics.balance} style={{ minWidth: 200 }}>
          <Text type={parseFloat(balance.toString()) > 0 ? 'success' : 'warning'} strong>{currency(balance)}</Text>
        </Card>
        <Card title={configContext.locale.Transactions.statistics.zero_date} style={{ minWidth: 200 }}>
          <Text strong>{zeroDate !== null ? date(zeroDate) : '--'}</Text>
        </Card>
      </Space>

      <Space style={{ display: "flex", justifyContent: "space-between", padding: '10px 0' }}>
        <Button type="primary" onClick={showModal}>
          {configContext.locale.Transactions.actions.new_transaction}
        </Button>
        <RangePicker
          defaultValue={datesRange && datesRange.map(d => dayjs(d))}
          disabledDate={disabledDate}
          onChange={changeRange}
          renderExtraFooter={() => (<div style={{ paddingLeft: "1em" }}>{configContext.locale.Transactions.actions.date_picker_footer}</div>)}
        />
        <Radio.Group value={view}>
          <Radio.Button value={TransactionsView.CHART} onClick={() => setView(TransactionsView.CHART)} defaultChecked>
            {configContext.locale.Transactions.actions.view_toggle.chart}
          </Radio.Button>
          <Radio.Button value={TransactionsView.TABLE} onClick={() => setView(TransactionsView.TABLE)}>
            {configContext.locale.Transactions.actions.view_toggle.table}
          </Radio.Button>
        </Radio.Group>
      </Space>

      <TransactionsForm visible={isModalVisible} okHandler={handleOk} cancelHandler={handleCancel} />

      {transactionsRepresentation}
    </>
  )
}

export default Transactions;
