const randomKey = (l) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < l; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
 }
 return result;
}

export default randomKey;
