import {Button, Card, ConfigProvider, Empty, Space} from "antd";
import {useContext, useState, useEffect, useCallback} from "react";
import {WSContext} from "../../providers/WSProvider";
import GroupsCard from "../../components/GroupsCard";
import randomKey from "../../utils/randomKey";

const { ConfigContext } = ConfigProvider;

export default function Groups() {
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [groupsContent, setGroupsContent] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const addNewGroup = () => {
    const key = randomKey(16);
    setGroupsContent([
      <GroupsCard id={key} key={key} removeCard={() => removeCard(key)} />,
      ...groupsContent,
    ]);
  }

  const removeCard = useCallback((key) => {
    setGroupsContent(groups => groups.filter(card => card.key !== key));
  }, []);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      wsContext.sendRequest(wsContext.prepareRequest('groups.get_list')).then(
        data => {
          setGroupsContent(
            data['groups'].map(group => {
              return (
                <GroupsCard
                  loaded={true}
                  groupData={group}
                  key={group.id}
                  removeCard={() => removeCard(group.id)}
                />
              );
            })
          );
        }
      )
    }
  }, [wsContext, removeCard, loaded, setGroupsContent])

  return (
    <Card
      title={configContext.locale.Settings.sections.groups}
      extra={(
        <Button
          onClick={addNewGroup}
        >
          {configContext.locale.Settings.groups.add_new}
        </Button>
      )}
      style={{ maxWidth: 600, width: 600 }}
    >
      <Space wrap style={{ display: 'flex' }} direction="vertical" size="middle">
        {groupsContent.length > 0 ? groupsContent : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)}
      </Space>
      <div id="bottom" />
    </Card>
  )
}
