import React, {createContext} from "react";
import config from "../config.json";

const WSContext = createContext({
  sendRequest: (request) => new Promise(() => undefined),
  prepareRequest: (method, params) => undefined,
  prepareBatchRequest: (...singleRequests) => singleRequests,
  subscribe: (topic, callback) => undefined,
  unsubscribe: (topic) => undefined,
});

const WSProvider = ({children}) => {
  let socket;
  let handlers = {};
  let subscriptions = {};

  const prepareRequest = (method, params) => {
    return {
      jsonrpc: "2.0",
      id: Math.round(Math.random() * 1000000000),
      method: method,
      params: params,
    }
  }

  const prepareBatchRequest = (...singleRequests) => {
    return singleRequests;
  }

  const sendRequest = (request) => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        socket = new WebSocket(config.api_url);
      }

      handlers[request.id || request[0].id] = {
        resolve,
        reject,
      };
      // TODO: perhaps add socket.onopen to pass current language
      socket.onmessage = (event) => {
        let parsed = JSON.parse(event.data)
        // if we have params we got a notification from server
        if (parsed['params']) {
          for (let topic in subscriptions) {
            if (topic === parsed['method']) {
              subscriptions[topic](parsed);
              return;
            }
          }
          return;
        }
        let handler = handlers[parsed['id'] || parsed[0]['id']];
        delete handlers[parsed['id'] || parsed[0]['id']];
        if (!Array.isArray(parsed)) {
          if (parsed['error']) {
            handler['reject'](parsed['error']);
          } else {
            handler['resolve'](parsed['result']);
          }
        } else {
          let success = [];
          let errors = [];
          for (let row of parsed) {
            if (row['error']) {
              errors.push(row['error']);
            } else {
              // TODO: maybe pass result only
              success.push(row);
            }
          }
          // TODO: either success or errors can be shown by the nature of Promise
          if (success.length) {
            resolve(success);
          }
          if (errors.length) {
            reject(errors);
          }
        }
      }

      socket.onerror = (event) => {
        reject(event);
      }

      socket.onclose = (event) => {
        // TODO: investigate ws disconnections & how that may be prevented
        // refresh page
        window.location.href = '/';
      }

      const id = setInterval(() => {
        if (socket.readyState === socket.OPEN) {
          clearInterval(id);
          socket.send(JSON.stringify(request));
        } else if (socket.readyState > socket.OPEN) {
          clearInterval(id);
          socket = null;
          // refresh page
          window.location.href = '/';
        }
      }, 50)
    })
  }

  const subscribe = (topic, callback) => {
    subscriptions[topic] = callback;
    return sendRequest(prepareRequest('subscribe', [topic]));
  }

  const unsubscribe = (topic) => {
    delete subscriptions[topic];
    return sendRequest(prepareRequest('unsubscribe', [topic]));
  }

  return (
    <WSContext.Provider value={{
      sendRequest,
      prepareRequest,
      prepareBatchRequest,
      subscribe,
      unsubscribe,
    }}>
      {children}
    </WSContext.Provider>
  );
}

export {
  WSContext,
  WSProvider
};
