import {useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ConfigProvider, Form, message, Space} from "antd";
import PasswordConfirmation from "../components/PasswordConfirmation";
import {WSContext} from "../providers/WSProvider";
import {replaceMessage} from "../utils/formatters";

const { ConfigContext } = ConfigProvider;

export default function PasswordRestore() {
  let { token } = useParams();
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const restorePassword = (data) => {
    wsContext.sendRequest(
      wsContext.prepareRequest('auth.restore_password', {token: token, password: data['password']})
    ).then(
      data => {
        if (data['password_restored'] === 1) {
          message.success(configContext.locale.PasswordRestore.success);
        } else {
          message.error(configContext.locale.PasswordRestore.failed);
        }
        navigate('/login/');
      },
      error => {
        console.error(error);
        form.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      },
    );
  }

  return (
    <Space style={{ width: '100%', justifyContent: 'center' }}>
      <PasswordConfirmation form={form} onFinish={restorePassword} />
    </Space>
  )
}
