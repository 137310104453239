import React, {useContext} from 'react';
import {Input, InputNumber, Form, Table, ConfigProvider} from 'antd';
import Select from "./Select";

const { ConfigContext } = ConfigProvider;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  required,
  ...restProps
}) => {
  let inputNode = <Input />;
  if (inputType === 'number') {
    inputNode = <InputNumber />;
  } else if (inputType === 'select') {
    // TODO: not applicable for all scenarios
    // restProps.data.filter(row => row['id'] !== record['id'])
    inputNode = <Select data={restProps.data} selected={record[dataIndex]} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{required: required !== undefined ? required : true}]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({columns, form, data, cancel}) => {
  const configContext = useContext(ConfigContext);

  return (
    <Form form={form} validateMessages={{required: configContext.locale.Forms.errors.required}}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};


export {
  EditableCell,
  EditableTable,
};
