import PasswordConfirmation from "../../components/PasswordConfirmation";
import {Card, ConfigProvider, Form, message} from "antd";
import {useContext} from "react";
import {WSContext} from "../../providers/WSProvider";

const { ConfigContext } = ConfigProvider;

export default function Security() {
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [form] = Form.useForm();

  const saveNewPassword = (data) => {
    wsContext.sendRequest(
      wsContext.prepareRequest('auth.update_password', {password: data['password']})
    ).then(
      () => {
        message.success(configContext.locale.Settings.security.password_updated);
        form.resetFields();
      },
      error => {
        console.error(error);
        message.error(error.message);
      }
    )
  }

  return (
    <Card
      title={configContext.locale.Settings.sections.security}
      style={{ maxWidth: 600, width: 600 }}
    >
      <div>
        <h4>{configContext.locale.Settings.security.change_password}</h4>
        <PasswordConfirmation form={form} onFinish={saveNewPassword} />
      </div>
    </Card>
  );
}
