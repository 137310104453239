import {useCallback, useContext, useEffect, useState} from "react";
import {Button, Card, ConfigProvider, Empty, Form, Input, List, message, Modal, Popconfirm} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {AuthContext} from "../providers/AuthProvider";
import {WSContext} from "../providers/WSProvider";
import {replaceMessage} from "../utils/formatters";
import {getMemberName} from "../utils/getMemberName";

const { ConfigContext } = ConfigProvider;

export default function GroupsCard({loaded, groupData, removeCard}) {
  loaded = loaded === undefined ? false : loaded;

  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [editing, setEditing] = useState(!loaded);
  const [extra, setExtra] = useState((<></>));
  const [members, setMembers] = useState(groupData?.members || []);
  const [name, setName] = useState(groupData?.name || '');
  const [realName, setRealName] = useState(groupData?.name || '');
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(groupData?.id);
  const [memberForm] = Form.useForm();
  const title = editing ? (<Input onChange={e => setName(e.target.value)} value={name} />) : realName;
  const validateMessages = {
    required: configContext.locale.Forms.errors.required,
    types: {email: configContext.locale.Forms.errors.email},
  };

  const saveGroupName = useCallback(() => {
    setEditing(false);
    setRealName(name);
    if (id === undefined) {
      wsContext.sendRequest(
        wsContext.prepareRequest('groups.create', {name}),
      ).then(
        data => {
          message.success(
            replaceMessage(configContext.locale.Settings.groups.group_added, {name}),
          );
          setMembers(data['group']['members']);
          setId(data['group']['id']);
        },
        error => {
          message.error(error.message);
          console.error(error)
        },
      )
    } else {
      wsContext.sendRequest(
        wsContext.prepareRequest('groups.update', {name, id}),
      ).then(
        data => {
          if (data['group']['updated'] === 1) {
            message.success(
              replaceMessage(configContext.locale.Settings.groups.group_updated, {name}),
            );
          }
        },
        error => {
          message.error(error.message);
          console.error(error);
        },
      )
    }
  }, [setEditing, setRealName, name, wsContext, id, setId, configContext]);
  const cancelEditingGroupName = useCallback(() => {
    setEditing(false);
    if (id === null) {
      removeCard();
    }
  }, [setEditing, id, removeCard]);
  const editGroupName = useCallback(() => {
    setEditing(true);
  }, [setEditing]);
  const deleteGroup = useCallback(() => {
    wsContext.sendRequest(
      wsContext.prepareRequest('groups.delete', {id}),
    ).then(
      () => {
        message.success(
          replaceMessage(configContext.locale.Settings.groups.group_deleted, {name}),
        );
        removeCard();
      },
      error => {
        message.error(error.message);
        console.error(error);
      },
    );
  }, [wsContext, removeCard, id, name, configContext]);
  const deleteGroupMember = (membersId) => {
    wsContext.sendRequest(
      wsContext.prepareRequest('groups.delete_member', {members_id: membersId, groups_id: id}),
    ).then(
      data => {
        if (data['member']['deleted'] === 1) {
          message.success(
            replaceMessage(
              configContext.locale.Settings.groups.member_removed,
              {email: members.filter(m => m.id === membersId)[0]['email'], name},
            ),
        );
          setMembers(m => m.filter(member => member.id !== membersId));
        }
      },
      error => {
        message.error(error.message);
        console.error(error);
      }
    )
  }

  const addMember = () => {
    setShowModal(true);
  }
  const sendInvitation = () => {
    memberForm.validateFields().then(
      formData => {
        wsContext.sendRequest(
          wsContext.prepareRequest('email.send_group_invitation', {...formData, groups_id: id}),
        ).then(
          data => {
            message.success(
              replaceMessage(
                configContext.locale.Settings.groups.member_added,
                {email: data['member']['email'], name},
              ),
            );
            setMembers(m => [data['member'], ...m]);
            setShowModal(false);
          },
          error => {
            message.error(error.message);
            console.error(error);
          }
        )
      },
      e => console.error(e),
    );
  }

  const leaveGroup = useCallback(() => {
    wsContext.sendRequest(
      wsContext.prepareRequest('groups.leave_group', {groups_id: id}),
    ).then(
      () => {
        message.success(
          replaceMessage(configContext.locale.Settings.groups.group_left, {name}),
        );
        removeCard();
      },
      error => {
        message.error(error.message);
        console.error(error);
      }
    )
  }, [configContext, name, wsContext, id, removeCard]);

  const renderMembers = (members) => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={members}
        renderItem={member => (
          <List.Item
            actions={groupData?.is_owner && member['id'] !== authContext.user['id'] ? [
              <Popconfirm
                title={configContext.locale.ActionButtons.delete_prompt}
                onConfirm={() => deleteGroupMember(member['id'])}
              >
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            ] : []}
          >
            {getMemberName(
              member,
              authContext.user['email'],
              configContext.locale.Phrases.you,
              configContext.locale.Phrases.anonymous,
            )}
          </List.Item>
        )}
      />
    );
  }

  useEffect(() => {
    if (editing) {
      setExtra((
        <div>
          <Button onClick={saveGroupName} icon={<CheckOutlined />} style={{ margin: '0 8px' }} />
          <Button onClick={id ? cancelEditingGroupName : removeCard} icon={<CloseOutlined />} style={{ marginRight: 8 }} />
        </div>
      ));
    } else if ([true, undefined].includes(groupData?.is_owner)) {
      setExtra((
        <div>
          <Button onClick={addMember} icon={<PlusOutlined />} style={{ margin: '0 8px' }} />
          <Button onClick={editGroupName} icon={<EditOutlined />} style={{ marginRight: 8 }} />
          <Popconfirm title={configContext.locale.ActionButtons.delete_prompt} onConfirm={deleteGroup}>
            <Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} />
          </Popconfirm>
        </div>
      ))
    } else {
      setExtra((
        <div>
          <Popconfirm title={configContext.locale.Settings.groups.leave_group_prompt} onConfirm={leaveGroup}>
            <Button style={{ marginRight: 8 }}>{configContext.locale.Settings.groups.leave_group}</Button>
          </Popconfirm>
        </div>
      ));
    }
  }, [
    editing,
    saveGroupName,
    id,
    cancelEditingGroupName,
    removeCard,
    editGroupName,
    configContext,
    deleteGroup,
    groupData,
    leaveGroup,
  ]);

  return (
    <Card bordered title={title} size="small" extra={extra}>
      {members.length > 0 ? renderMembers(members) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      <Modal
        destroyOnClose={true}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={sendInvitation}
        footer={[
          <Button key="cancel">
            {configContext.locale.Modal.cancelText}
          </Button>,
          <Button key="submit" type="primary" onClick={sendInvitation}>
            {configContext.locale.Button.invite}
          </Button>,
        ]}
        title={configContext.locale.Settings.groups.new_member}
      >
        <Form form={memberForm} validateMessages={validateMessages}>
          <Form.Item label={configContext.locale.Settings.groups.members_email} name='email' rules={[{required: true, type: "email"}]}>
            <Input placeholder={configContext.locale.Settings.groups.members_email} />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}
