import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ConfigProvider} from "antd";
import './App.css';
import {WSProvider} from "./providers/WSProvider";
import {AuthContext, AuthProvider} from "./providers/AuthProvider";
import Menu from "./components/Menu";
import {supportedLocales} from "./utils/constants";
import {GoogleOAuthProvider} from '@react-oauth/google';

function App() {
  const getLocale = (context) => {
    if (context && context.settings && context.settings['language'] in supportedLocales) {
      localStorage.setItem('language', context.settings['language']);
      return supportedLocales[context.settings['language']];
    }

    const language = localStorage.getItem('language');
    if (language !== undefined && language in supportedLocales) {
      return supportedLocales[language];
    }

    localStorage.setItem('language', 'en_GB');

    return supportedLocales['en_GB'];
  };

  return (
    <Router>
      <GoogleOAuthProvider clientId='990489213130-qs5719se7140stbnj9bfhgss62s8hrqp.apps.googleusercontent.com'>
        <WSProvider>
          <AuthProvider>
            <AuthContext.Consumer>
              {context => (
                <ConfigProvider
                  locale={getLocale(context)}
                  dropdownMatchSelectWidth={false}
                >
                  <Menu user={context.user} />
                </ConfigProvider>
              )}
            </AuthContext.Consumer>
          </AuthProvider>
        </WSProvider>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
