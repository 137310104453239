import {WSContext} from "../providers/WSProvider";
import {useContext, useEffect, useState} from "react";
import {Form, Input, Button, ConfigProvider} from "antd";
import {EditableTable} from "../components/EditableTable";
import ActionsButtons from "../components/ActionsButtons";
import Select from "../components/Select";
import {replaceMessage} from "../utils/formatters";

const { ConfigContext } = ConfigProvider;

export default function Products() {
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [tableForm] = Form.useForm();
  const [productForm] = Form.useForm();
  const [productsData, setProductsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = key => editingKey === key;

  const columns = [{
    title: configContext.locale.Products.table.name,
    key: 'name',
    dataIndex: 'name',
    editable: true,
  }, {
    title: configContext.locale.Products.table.category,
    key: 'categories_id',
    dataIndex: 'category_name',
    editable: true,
    inputType: 'select',
    data: categoriesData,
  }, {
    title: configContext.locale.Products.table.actions,
    key: 'id',
    dataIndex: 'id',
    render: (_, record) => (
      <ActionsButtons
        id={record}
        isEditing={isEditing(record.id)}
        deleteMethod={deleteProduct}
        editMethod={editProduct}
        updateMethod={updateProduct}
        cancel={cancel}
      />
    ),
    width: 250,
  }];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.key,
        title: col.title,
        editing: isEditing(record.id),
        inputType: col.inputType,
        data: col.data,
        required: col.required,
      }),
    };
  });

  const cancel = () => {
    setEditingKey('');
  };

  const addProduct = (data) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'products.create',
        data,
      ),
    ).then(
      () => {
        setLoaded(false);
        productForm.resetFields();
      },
      error => {
        console.error(error);
        productForm.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      },
    );
  }
  const editProduct = (product) => {
    tableForm.setFieldsValue({
      name: product.name,
      categories_id: product.categories_id,
    });
    setEditingKey(product.id);
  };
  const updateProduct = (product) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'products.update',
        {
          id: product.id,
          ...tableForm.getFieldsValue(),
        }
      )
    ).then(
      (data) => {
        if (data['product']['updated'] === 1) {
          cancel(product.id);
          setLoaded(false);
        } else {
          console.error('failed to update');
        }
      },
      error => {
        console.error(error);
        tableForm.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      },
    );
  }
  const deleteProduct = (product) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'products.delete',
        {
          id: product.id,
        }
      ),
    ).then(
      () => {
        setLoaded(false);
      }
    );
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      wsContext.sendRequest(
        wsContext.prepareBatchRequest(
          wsContext.prepareRequest('products.get_list'),
          wsContext.prepareRequest('categories.get_list'),
        )
      ).then(
        (response) => {
          if (!response) {
            console.error('empty response', response);
            return;
          }

          let [_products, _categories] = response;

          if (_products && _products['result']) {
            setProductsData(_products['result']['products'].map(row => {
              row['key'] = row['id'];
              return row;
            }));
          }

          if (_categories && _categories['result']) {
            setCategoriesData(_categories['result']['categories'].map(category => {
              return {
                ...category,
                value: category.id,
                label: category.name,
              };
            }));
          }
        }
      );
    }
  }, [loaded, wsContext]);

  return (
    <>
      <Form
        onFinish={addProduct}
        form={productForm}
        layout='inline'
        style={{ paddingBottom: 10 }}
        validateMessages={{required: configContext.locale.Forms.errors.required}}
      >
        <Form.Item
          name='name'
          label={configContext.locale.Products.form.name}
          rules={[{required: true}]}
        >
          <Input placeholder={configContext.locale.Products.form.name_placeholder} />
        </Form.Item>
        <Form.Item
          name='categories_id'
          label={configContext.locale.Products.form.category}
          rules={[{required: true}]}
        >
          <Select placeholder={configContext.locale.Products.form.category_placeholder} data={categoriesData} />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit'>
            {configContext.locale.Button.add}
          </Button>
        </Form.Item>
      </Form>

      <EditableTable columns={mergedColumns} form={tableForm} cancel={cancel} data={productsData} />
    </>
  );
}
