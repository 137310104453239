import {ConfigProvider, Layout, Menu as AntMenu} from 'antd';
import {Link, Route, Routes, useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";
import ProtectedRoute from "./ProtectedRoute";
import {
  BarsOutlined,
  CoffeeOutlined,
  DollarOutlined,
  DownCircleOutlined,
  LoginOutlined,
  LogoutOutlined,
  SettingOutlined, UserAddOutlined,
} from "@ant-design/icons";
import {
  Categories,
  Login,
  Logout,
  PasswordRestore,
  Products,
  Settings,
  Transactions
} from "../pages";

const { Content, Header } = Layout;
const { ConfigContext } = ConfigProvider;

export default function Menu({user}) {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const configContext = useContext(ConfigContext);

  if (current !== location.pathname) {
    setCurrent(location.pathname);
  }

  const getUserGreeting = (user) => {
    let greeting = `${configContext.locale.Phrases.greeting}, `;
    if (user['first_name']) {
      greeting += user['first_name'];
      if (user['last_name']) {
        greeting += ' ' + user['last_name'];
      }
    } else {
      greeting += configContext.locale.Phrases.anonymous;
    }

    return greeting;
  }

  const items = [{
    key: '/',
    icon: (<DollarOutlined />),
    label: (<Link to='/'>{configContext.locale.PageNames.transactions}</Link>),
  }, {
    key: '/products/',
    icon: (<CoffeeOutlined/>),
    label: (<Link to='/products/'>{configContext.locale.PageNames.products}</Link>),
  }, {
    key: '/categories/',
    icon: (<BarsOutlined />),
    label: (<Link to='/categories/'>{configContext.locale.PageNames.categories}</Link>),
  }];
  if (user === null) {
    items.push({
      key: '/login/',
      icon: (<LoginOutlined />),
      style: { marginLeft: 'auto' },
      label: (<Link to='/login/'>{configContext.locale.PageNames.login}</Link>),
    });
  } else {
    items.push({
      key: 'new_member',
      icon: (<UserAddOutlined />),
      style: { marginLeft: 'auto' },
      label: (<Link to='/settings/groups/' />),
    }, {
      key: 'user',
      icon: (<DownCircleOutlined />),
      label: getUserGreeting(user),
      // style: { marginLeft: 'auto' },
      children: [{
        key: '/settings/',
        icon: (<SettingOutlined/>),
        label: (<Link to='/settings/account/'>{configContext.locale.PageNames.settings}</Link>),
      }, {
        key: '/logout/',
        icon: (<LogoutOutlined />),
        label: (<Link to='/logout/'>{configContext.locale.PageNames.logout}</Link>),
      }],
    });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <AntMenu items={items} mode='horizontal' theme='dark' selectedKeys={[current]} onClick={e => setCurrent(e.key)} />
      </Header>
      <Content style={{ padding: '10px 50px' }}>
        <Routes>
          <Route path='/' element={<ProtectedRoute component={Transactions} />} />
          <Route path='/products/' element={<ProtectedRoute component={Products} />} />
          <Route path='/categories/' element={<ProtectedRoute component={Categories} />} />
          <Route path='/settings/:settingsType/:token/' element={<ProtectedRoute component={Settings} />} />
          <Route path='/settings/:settingsType/' element={<ProtectedRoute component={Settings} />} />
          {user === null ? (
            <>
              <Route path='/password/restore/:token/' element={<PasswordRestore />} />
              <Route path='/login/' element={<Login />} />
            </>
          ) : (
            <Route path='/logout/' element={<ProtectedRoute component={Logout} />} />
          )}
          <Route path='*' element={<div>{configContext.locale.Phrases.page_404}</div>} />
        </Routes>
      </Content>
    </Layout>
  )
}
