import {AuthContext} from "../providers/AuthProvider";
import {WSContext} from "../providers/WSProvider";
import {useContext, useEffect, useState} from "react";
import {Form, Button, Input, message, ConfigProvider} from "antd";
import loadData from "../utils/loadData";
import {EditableTable} from "../components/EditableTable";
import ActionsButtons from "../components/ActionsButtons";
import Select from "../components/Select";
import {replaceMessage} from "../utils/formatters";

const { ConfigContext } = ConfigProvider;

export default function Categories() {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [tableForm] = Form.useForm();
  const [categoryForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = key => editingKey === key;

  const columns = [{
    title: configContext.locale.Categories.table.name,
    key: 'name',
    dataIndex: 'name',
    editable: true,
  }, {
    title: configContext.locale.Categories.table.parent_category,
    key: 'parent_categories_id',
    dataIndex: 'parent_name',
    editable: true,
    inputType: 'select',
    required: false,
    data,
  }, {
    title: configContext.locale.Categories.table.actions,
    key: 'id',
    dataIndex: 'id',
    render: (_, record) => (
      <ActionsButtons
        id={record}
        isEditing={isEditing(record.id)}
        deleteMethod={deleteCategory}
        editMethod={editCategory}
        updateMethod={updateCategory}
        cancel={cancel}
      />
    ),
    width: 250,
  }];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.key,
        title: col.title,
        editing: isEditing(record.id),
        inputType: col.inputType,
        data: col.data,
        required: col.required,
      }),
    };
  });

  const cancel = () => {
    setEditingKey('');
  };

  const addCategory = (data) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'categories.create',
        data
      ),
    ).then(
      () => {
        setLoaded(false);
        categoryForm.resetFields();
      },
      (error) => {
        console.error(error);
        categoryForm.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      },
    )
  }
  const editCategory = (category) => {
    tableForm.setFieldsValue({
      name: category.name,
      parent_categories_id: category.parent_categories_id,
    });
    setEditingKey(category.id);
  };
  const updateCategory = (record) => {
    const categoriesId = tableForm.getFieldsValue().parent_categories_id
    const setCategoryAsNull = record.parent_categories_id !== categoriesId && categoriesId === undefined;
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'categories.update',
        {
          id: record.id,
          name: tableForm.getFieldsValue().name,
          parent_categories_id: setCategoryAsNull ? null : categoriesId,
        }
      )
    ).then(
      (data) => {
        if (data['category']['updated'] === 1) {
          cancel(record.id);
          setLoaded(false);
        } else {
          console.error('failed to update');
        }
      },
      (error) => {
        message.error(error.message);
        tableForm.setFields([{
          name: 'name',
          errors: [error.data.name],
        }]);
      }
    )
  }
  const deleteCategory = (category) => {
    if (category.id < 0) {
      message.warning(configContext.locale.Categories.form.cannot_delete_system_category);
      return;
    }
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'categories.delete',
        {
          id: category.id,
        }
      ),
    ).then(
      () => {
        setLoaded(false);
      },
    );
  }

  useEffect(() => {
    if (!loaded && authContext.user) {
      setLoaded(true);
      loadData('categories', wsContext).then(
        (categories) => {
          setData(categories.map(category => {
            return {
              ...category,
              label: category.name,
              value: category.id,
            };
          }));
        },
      );
    }
  }, [loaded, authContext, wsContext]);

  return (
    <>
      <Form
        onFinish={addCategory}
        form={categoryForm}
        layout='inline'
        style={{ paddingBottom: 10 }}
        validateMessages={{required: configContext.locale.Forms.errors.required}}
      >
        <Form.Item
          name='name'
          label={configContext.locale.Categories.form.name}
          rules={[{required: true}]}
        >
          <Input placeholder={configContext.locale.Categories.form.name_placeholder} />
        </Form.Item>
        <Form.Item name='parent_categories_id' label={configContext.locale.Categories.form.parent_category}>
          <Select placeholder={configContext.locale.Categories.form.parent_category_placeholder} data={data} />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit'>
            {configContext.locale.Button.add}
          </Button>
        </Form.Item>
      </Form>

      <EditableTable columns={mergedColumns} form={tableForm} cancel={cancel} data={data} />
    </>
  );
}
