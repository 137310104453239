import {Button, Form, Input, message, Space, ConfigProvider, Tabs} from "antd";
import {LockOutlined, MailOutlined, UnlockOutlined} from "@ant-design/icons";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../providers/AuthProvider";
import {WSContext} from "../providers/WSProvider";
import {replaceMessage} from "../utils/formatters";

const { ConfigContext } = ConfigProvider;

export default function Login() {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const navigate = useNavigate();
  const [logInForm] = Form.useForm();
  const [signUpForm] = Form.useForm();
  const [lostForm] = Form.useForm();

  const registerOrLogin = (form, action) => () => {
    wsContext.sendRequest(
      wsContext.prepareRequest(action, form.getFieldsValue()),
    ).then(
      response => {
        authContext.verifiedSuccessfully(response);
        navigate('/');
      },
      error => {
        console.error(error);
        if (error.data && error.data['name']) {
          form.setFields([{
            name: error.data['name'],
            errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
          }]);
        } else {
          message.error(configContext.locale.Forms.server_validation[error.message]);
        }
      }
    )
  }

  const sendForgotPasswordInstructions = () => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'email.send_password_restore',
        {
          ...lostForm.getFieldsValue(),
          language: configContext.locale.language,
        },
      ),
    ).then(
      result => {
        console.log(result);
        message.info(replaceMessage(configContext.locale.Login.form.message_sent, lostForm.getFieldsValue()));
      },
      error => {
        console.error(error);
        lostForm.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      }
    );
  }

  const tabs = [
    {
      key: "miser",
      label: configContext.locale.Login.form.miser,
      children: (
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          onFinish={registerOrLogin(logInForm, 'login')}
          form={logInForm}
          validateMessages={{
            required: configContext.locale.Forms.errors.required,
            types: {email: configContext.locale.Forms.errors.email},
          }}
          style={{ maxWidth: 272 }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
              },
            ]}
          >
            <Input suffix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            label={configContext.locale.Login.form.password}
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password
              placeholder={configContext.locale.Login.form.password}
              iconRender={visible => (visible ? <UnlockOutlined /> : <LockOutlined />)}
            />
          </Form.Item>

          <div style={{ display: "flex", alignItems: "center", flexFlow: "row", justifyContent: "center", marginBottom: 24 }}>
            <Button type="primary" htmlType="submit">
              {configContext.locale.Login.form.log_in}
            </Button>
            <span style={{ padding: "0 4px" }}>{configContext.locale.Phrases.or}</span>
            <Button onClick={authContext.signIn}>
              {configContext.locale.Login.form.google_sign_in}
            </Button>
          </div>

        </Form>
      ),
    },
    {
      key: "newbie",
      label: configContext.locale.Login.form.newbie,
      children: (
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          onFinish={registerOrLogin(signUpForm, 'register')}
          form={signUpForm}
          validateMessages={{
            required: configContext.locale.Forms.errors.required,
            types: {email: configContext.locale.Forms.errors.email},
          }}
          style={{ maxWidth: 272 }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
              },
            ]}
          >
            <Input suffix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            label={configContext.locale.Login.form.password}
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password
              placeholder={configContext.locale.Login.form.password}
              iconRender={visible => (visible ? <UnlockOutlined /> : <LockOutlined />)}
            />
          </Form.Item>

          <div style={{ display: "flex", alignItems: "center", flexFlow: "row", justifyContent: "center", marginBottom: 24 }}>
            <Button type="primary" htmlType="submit">
              {configContext.locale.Login.form.sign_up}
            </Button>
          </div>
        </Form>
      ),
    },
    {
      key: "lost",
      label: configContext.locale.Login.form.lost,
      children: (
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          onFinish={sendForgotPasswordInstructions}
          form={lostForm}
          validateMessages={{
            required: configContext.locale.Forms.errors.required,
            types: {email: configContext.locale.Forms.errors.email},
          }}
          style={{ maxWidth: 272 }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
              },
            ]}
          >
            <Input suffix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "center", flexFlow: "row" }}>
            <Button htmlType="submit">{configContext.locale.Login.form.password_restore}</Button>
          </div>
        </Form>
      ),
    }
  ]

  return (
    <Space style={{ width: '100%', justifyContent: 'center' }}>
      <Tabs defaultActiveKey="miser" items={tabs} centered />
    </Space>
  )
}
