import {useContext} from 'react';
import {Button, ConfigProvider, Form, Input} from "antd";
import {LockOutlined, UnlockOutlined} from "@ant-design/icons";

const { ConfigContext } = ConfigProvider;

export default function PasswordConfirmation({ form, onFinish }) {
  const configContext = useContext(ConfigContext);
  const validateMessages = {
    required: configContext.locale.Forms.errors.required,
  };

  return (
    <Form
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 20 }}
      form={form}
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        label={configContext.locale.Settings.form.password}
        name='password'
        rules={[{required: true}]}
      >
        <Input.Password
          iconRender={visible => (visible ? <UnlockOutlined /> : <LockOutlined />)}
          placeholder={configContext.locale.Settings.form.password}
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={configContext.locale.Settings.form.confirm_password}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(configContext.locale.Settings.form.errors.missmatch));
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={visible => (visible ? <UnlockOutlined /> : <LockOutlined />)}
          placeholder={configContext.locale.Settings.form.confirm_password}
        />
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type='primary' style={{ float: "right"}} htmlType='submit'>
          {configContext.locale.Button.update}
        </Button>
      </div>
    </Form>
  );
}
