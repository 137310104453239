import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Form as AForm,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Select as ASelect,
} from "antd";
import {TransactionType} from "../../utils/constants";
import Select from "../Select";
import React, {useContext, useEffect, useState} from "react";
import {WSContext} from "../../providers/WSProvider";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {AuthContext} from "../../providers/AuthProvider";
import {replaceMessage} from "../../utils/formatters";
import dayjs from "dayjs";

const { ConfigContext } = ConfigProvider;
const { Option } = ASelect;

export default function Form({ visible, okHandler, cancelHandler }) {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [transactionForm] = AForm.useForm();
  const [productForm] = AForm.useForm();
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [products, setProducts] = useState([]);
  // TODO: probably better to keep this logic on BE side
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [typedProducts, setTypedProducts] = useState([]);
  const [productsLabel, setProductsLabel] = useState(configContext.locale.Transactions.form.product);
  const [hideFields, setHideFields] = useState(false);
  const [newProductVisible, setNewProductVisible] = useState(false);
  const validationMessages = {
    required: configContext.locale.Forms.errors.required,
    types: {
      number: configContext.locale.Forms.errors.number,
    },
  };

  const initialValues = {
    receipt_type: TransactionType.EXPENSE,
    quantity: 1,
    created_at: dayjs(),
    receipt_items: [{
      quantity: 1,
      created_at: dayjs(),
    }],
  }
  const handleOk = () => {
    transactionForm.validateFields().then(
      formData => {
        const receiptType = formData['receipt_type'];
        let items = formData['receipt_items'].map(item => {
          return {
            ...item,
            price: item['price'].toString(),
            quantity: item['quantity'].toString(),
          };
        });

        wsContext.sendRequest(
          wsContext.prepareRequest(
            'receipts.create',
            {
              receipt_type: receiptType,
              items,
            },
          )
        ).then(
          () => {
            transactionForm.resetFields();
            okHandler();
          },
          (errors) => {
            console.error(errors);
            errors.map((error, index) => {
              transactionForm.setFields([{
                name: ['receipt_items', index, error.data['name']],
                errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
              }]);
              return null;
            });
          },
        )
      },
      e => console.error(e),
    )
  }

  const openCreateProduct = (transactionIndex) => {
    const productName = filteredProducts.filter(p => p.value === null)[0].label;
    productForm.setFields([{
      name: 'name',
      value: productName,
    }, {
      name: 'receipt_item_index',
      value: transactionIndex,
    }]);
    setNewProductVisible(true);
  }

  const createProduct = () => {
    const formData = productForm.getFieldsValue();
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'products.create',
        {
          name: formData['name'],
          categories_id: formData['categories_id'],
        },
      )
    ).then(
      (data) => {
        transactionForm.setFields([{
          name: ['receipt_items', formData['receipt_item_index'], 'products_id'],
          value: data['product']['id'],
          errors: [],
        }]);
        setProducts(
          [
            ...products,
            {
              ...data['product'],
              value: data['product']['id'],
              label: data['product']['name'],
            },
          ]
        );
        setTypedProducts(
          [
            ...typedProducts,
            {
              ...data['product'],
              value: data['product']['id'],
              label: data['product']['name'],
            },
          ]
        );

        setNewProductVisible(false);
      },
      error => {
        console.error(error);
        productForm.setFields([{
          name: error.data['name'],
          errors: [replaceMessage(configContext.locale.Forms.server_validation[error.message], error.data)],
        }]);
      },
    )
  }

  const setProduct = (products_id) => {
    if (products_id === undefined) {
      setFilteredProducts(typedProducts);
    }
  }

  const onSearch = (input) => {
    setFilteredProducts(
      input ? [...typedProducts.filter(
        product => (
          product.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          && product.value !== null
        )
      ), {value: null, label: input}] : typedProducts
    );
  }

  const changeTransactionType = (event) => {
    setTypedProducts(products.filter(
      product => event.target.value === TransactionType.EXPENSE ? product['categories_id'] > 0 : product['categories_id'] < 0
    ));
    setFilteredProducts(products.filter(
      product => event.target.value === TransactionType.EXPENSE ? product['categories_id'] > 0 : product['categories_id'] < 0
    ));
    setProductsLabel(event.target.value === TransactionType.EXPENSE ? configContext.locale.Transactions.form.product : configContext.locale.Transactions.form.income_source);
    setHideFields(event.target.value === TransactionType.INCOME);
    transactionForm.resetFields();
    transactionForm.setFields([{
      name: 'receipt_type',
      value: event.target.value,
    }]);
  }

  useEffect(() => {
    if (!visible) {
      return;
    }

    let queries = [
      wsContext.prepareRequest('products.get_list'),
      wsContext.prepareRequest('categories.get_list'),
    ];
    if (authContext.settings.in_group) {
      queries.push(wsContext.prepareRequest('groups.get_list'));
    }

    wsContext.sendRequest(wsContext.prepareBatchRequest(...queries)).then(
      (response) => {
        if (!response) {
          console.error('empty response', response);
          return;
        }

        let _products, _categories, _groups = null;
        if (authContext.settings.in_group) {
          [_products, _categories, _groups] = response;
        } else {
          [_products, _categories] = response;
        }

        if (_products && _products['result']) {
          _products['result']['products'] = _products['result']['products'].map(row => {
            return {
              ...row,
              value: row['id'],
              label: row['name'],
            }
          });
          setProducts(_products['result']['products']);
          // initial products
          setTypedProducts(_products['result']['products'].filter(product => product['categories_id'] > 0));
          setFilteredProducts(_products['result']['products'].filter(product => product['categories_id'] > 0));
        }

        if (_categories && _categories['result']) {
          setCategories(_categories['result']['categories'].map(category => {
            return {
              ...category,
              value: category.id,
              label: category.name,
            };
          }));
        }

        if (_groups && _groups['result']) {
          setGroups(_groups['result']['groups'].map(group => {
            return {
              value: group.id,
              label: group.name,
            };
          }));
        }
      }
    )
  }, [wsContext, visible, authContext]);

  return (
    <Modal
      open={visible}
      maskClosable={false}
      closable={false}
      footer={[
        <Popconfirm key='cancel-confirm' title={configContext.locale.Transactions.form.close_confirm_prompt} onConfirm={cancelHandler}>
          <Button>
            {configContext.locale.Modal.cancelText}
          </Button>
        </Popconfirm>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {configContext.locale.Button.add}
        </Button>,
      ]}
      destroyOnClose={true}
    >
      <AForm
        form={transactionForm}
        preserve={false}
        name='transactions-form'
        initialValues={initialValues}
        autoComplete="off"
        labelCol={{ span: 10 }}
        validateMessages={validationMessages}
      >
        <AForm.Item
          label={configContext.locale.Transactions.form.transaction_type}
          name="receipt_type"
          rules={[{required: true}]}
        >
          <Radio.Group onChange={changeTransactionType}>
            <Radio.Button value={TransactionType.EXPENSE}>
              {configContext.locale.Transactions.form.expense}
            </Radio.Button>
            <Radio.Button value={TransactionType.INCOME}>
              {configContext.locale.Transactions.form.income}
            </Radio.Button>
          </Radio.Group>
        </AForm.Item>
        <AForm.List name='receipt_items'>
          {(fields, {add, remove}) => (
            <>
              {fields.map(({key, name, ...restFields}, index) => (
                <React.Fragment key={key}>
                  <Divider
                    hidden={index === 0}
                    children={<MinusCircleOutlined onClick={() => remove(name)} />}
                    orientation='right'
                    orientationMargin={0}
                  />
                  <AForm.Item
                    label={productsLabel}
                    name={[name, 'products_id']}
                    {...restFields}
                    rules={[{
                      required: true,
                      validator: (_, value) => {
                        if (value === undefined) {
                          return Promise.reject(configContext.locale.Forms.errors.required);
                        }
                        if (value === null) {
                          return Promise.reject((
                            <div key={['receipt_items', index, 'products_id']}>
                              {configContext.locale.Transactions.form.missing_product}.
                              <Button
                                type='link'
                                size='small'
                                style={{ padding: 0 }}
                                onClick={() => openCreateProduct(index)}
                              >
                                {configContext.locale.Button.create}?
                              </Button>
                            </div>
                          ));
                        }

                        return Promise.resolve();
                      },
                    }]}
                  >
                    <Select
                      placeholder={`${configContext.locale.Transactions.form.choose} ${productsLabel.toLowerCase()}`}
                      data={filteredProducts}
                      onChange={setProduct}
                      onSearch={onSearch}
                    />
                  </AForm.Item>
                  <AForm.Item
                    label={configContext.locale.Transactions.form.quantity}
                    name={[name, 'quantity']}
                    hidden={hideFields}
                    rules={[{required: true, type: "number"}]}
                  >
                    <InputNumber style={{ width: 190 }} step={0.001} />
                  </AForm.Item>
                  <AForm.Item
                    label={configContext.locale.Transactions.form.price}
                    name={[name, 'price']}
                    rules={[{required: true, type: "number"}]}
                  >
                    {/*TODO: take currency symbol from settings*/}
                    <InputNumber style={{ width: 190 }} addonAfter="₴" step={0.01} />
                  </AForm.Item>
                  <AForm.Item
                    label={configContext.locale.Transactions.form.transaction_date}
                    name={[name, 'created_at']}
                    rules={[{required: true}]}
                  >
                    <DatePicker
                      style={{ width: 190 }}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      locale={configContext.locale.DatePicker.lang.locale}
                    />
                  </AForm.Item>
                  {authContext.settings.in_group ? (
                    <AForm.Item label={configContext.locale.Transactions.form.groups} name={[name, 'groups_ids']}>
                      <ASelect
                        mode="multiple"
                        allowClear
                        style={{ width: 190 }}
                      >
                        {groups.map(group => (
                          <Option value={group.value} key={group.value}>{group.label}</Option>
                        ))}
                      </ASelect>
                    </AForm.Item>
                  ) : (<></>)}
                </React.Fragment>
              ))}
              <AForm.Item>
                <Button type="dashed" id='add_additional_item' onClick={() => {
                  add({
                    quantity: 1,
                    created_at: transactionForm.getFieldsValue()['receipt_items'].slice(-1)[0]['created_at'],
                    groups_ids: transactionForm.getFieldsValue()['receipt_items'].slice(-1)[0]['groups_ids'],
                  });
                  setTimeout(() => {
                    document.getElementById('add_additional_item').scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    });
                  }, 0);
                }} block icon={<PlusOutlined />}>
                  {configContext.locale.Transactions.form.additional_transaction}
                </Button>
              </AForm.Item>
            </>
          )}
        </AForm.List>
      </AForm>
      <Modal
        open={newProductVisible}
        footer={[
          <Button key='cancel-confirm' onClick={() => setNewProductVisible(false)}>
            {configContext.locale.Modal.cancelText}
          </Button>,
          <Button key="submit" type="primary" onClick={createProduct}>
            {configContext.locale.Button.add}
          </Button>,
        ]}
        maskClosable={true}
        closable={false}
        onCancel={() => setNewProductVisible(false)}
        width="fit-content"
        title={configContext.locale.Transactions.form.new_product}
      >
        <AForm form={productForm} validateMessages={validationMessages}>
          <AForm.Item label={productsLabel} name='name' rules={[{required: true}]}>
            <Input />
          </AForm.Item>
          <AForm.Item label={configContext.locale.Products.form.category} name='categories_id' rules={[{required: true}]}>
            <Select
              placeholder={configContext.locale.Transactions.form.choose}
              data={categories}
            />
          </AForm.Item>
          <AForm.Item name='receipt_item_index' hidden={true} rules={[{required: true}]}>
            <Input hidden={true} />
          </AForm.Item>
        </AForm>
      </Modal>
    </Modal>
  )
}
