const currency = (value) => {
  return new Intl.NumberFormat(
    navigator.language,
    {style: "currency", currency: "UAH"},
  ).format(value || 0);
}

const date = (value, withTime = undefined, locale = navigator.language) => {
  let options = {};
  if (withTime === true) {
    options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    };
  }
  return new Intl.DateTimeFormat(
    locale,
    options,
  ).format(value || new Date());
}

const quantity = (value, precision = 3) => {
  return new Intl.NumberFormat(
    navigator.language,
    {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    },
  ).format(value || 0);
}

const replaceMessage = (template, kv) => {
  return template.replace(
    /\${\w+}/g,
    (str) => {
      const key = str.slice(2, -1);
      return kv[key];
    },
  );
}

export {
  currency,
  date,
  quantity,
  replaceMessage,
}
