import {Button, ConfigProvider, Divider, Popconfirm} from "antd";
import {useContext} from "react";

const { ConfigContext } = ConfigProvider;

export default function ActionsButtons({
  id,
  isEditing,
  deleteMethod,
  editMethod,
  updateMethod,
  cancel,
  deleteOnly = false,
}) {
  const configContext = useContext(ConfigContext);

  if (!isEditing) {
    return (
      <>
        {deleteOnly ? <></> : (
          <>
            <Button onClick={() => editMethod(id)}>
              {configContext.locale.Button.update}
            </Button>
            <Divider type='vertical' />
          </>
        )}
        <Popconfirm title={configContext.locale.ActionButtons.delete_prompt} onConfirm={() => deleteMethod(id)}>
          <Button>
            {configContext.locale.Button.delete}
          </Button>
        </Popconfirm>
      </>
    );
  }

  return (
    <>
      <Button htmlType='submit' onClick={() => updateMethod(id)}>
        {configContext.locale.Button.save}
      </Button>
      <Divider type='vertical' />
      <Popconfirm title={configContext.locale.ActionButtons.cancel_prompt} onConfirm={cancel}>
        <Button>
          {configContext.locale.Button.cancel}
        </Button>
      </Popconfirm>
    </>
  );
}
