import {Alert, Button, Card, ConfigProvider, Input, message, Space} from "antd";
import {useContext, useState} from "react";
import {WSContext} from "../../providers/WSProvider";
import {AuthContext} from "../../providers/AuthProvider";

const { ConfigContext } = ConfigProvider;

export default function Integrations() {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [inputStatus, setInputStatus] = useState("");
  const MonoStatusTopic = 'mono_status';

  const monoStatusCallback = () => {
    wsContext.unsubscribe(MonoStatusTopic).then(
      () => {
        message.success(configContext.locale.Settings.integrations.monobank_enabled, 2).then(
          () => window.location.reload(),
        );
      },
      (error) => {
        console.error(error);
      },
    );
  }

  const setMonoWebhook = () => {
    const apiKey = document.getElementById("mono_api_key").value;
    if (!apiKey) {
      setInputStatus("error");
      return;
    }
    const monoUrl = 'https://api.monobank.ua/personal/webhook';
    const webHookUrl = `${window.location.origin}/mono/transaction/${authContext.user['id']}/`;
    wsContext.subscribe(MonoStatusTopic, monoStatusCallback);
    fetch(
      monoUrl,
      {
        method: 'POST',
        headers: {'X-Token': apiKey},
        body: JSON.stringify({webHookUrl}),
      },
    ).then(d => console.log(d));
  }

  const disableIntegration = (integrationName) => {
    wsContext.sendRequest(
      wsContext.prepareRequest(
        'settings.update_integration',
        {
          integration_name: integrationName,
          enabled: false,
        },
      )
    ).then(
      () => message.success(configContext.locale.Settings.integrations.disabled, 2).then(
        () => {
          window.location.reload();
        },
      ),
      error => {
        console.error(error);
        message.error(error.message);
      }
    )
  }

  let monoIntegration = (
    <Input.Group compact style={{display: "flex"}}>
      <Input
        placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
        id="mono_api_key"
        onPressEnter={setMonoWebhook}
        onChange={(event) => event.target.value ? setInputStatus("") : setInputStatus("error")}
        status={inputStatus}
      />
      <Button type="primary" onClick={setMonoWebhook}>
        {configContext.locale.Settings.integrations.enable}
      </Button>
    </Input.Group>
  );
  if (authContext.settings.mono_integration_enabled) {
    monoIntegration = (
      <Alert
        style={{marginBottom: 14}}
        message={configContext.locale.Settings.integrations.enabled}
        type="success"
        action={
          <Button type="primary" danger onClick={() => disableIntegration('mono')}>
            {configContext.locale.Settings.integrations.disable}
          </Button>
        }
      />
    );
  }

  return (
    <Card
      title={configContext.locale.Settings.sections.integrations}
      style={{ maxWidth: 600, width: 600 }}
    >
      <Space wrap style={{ display: 'flex' }} direction="vertical" size="middle">
        <Card title={configContext.locale.Settings.integrations.monobank}>
          {monoIntegration}
          <p dangerouslySetInnerHTML={{__html: configContext.locale.Settings.integrations.monobank_help}} />
          <Alert type="warning" message={configContext.locale.Settings.integrations.monobank_disclaimer} />
        </Card>
      </Space>
    </Card>
  )
}
