const loadData = (module, context, params = undefined) => {
  return new Promise((resolve, reject) => {
    // TODO: mix with defaults
    let body = {
      ...(params !== undefined ? params : {}),
    };
    context.sendRequest(context.prepareRequest(`${module}.get_list`, body)).then(
      (d) => {
        const data = d[module].map(row => {
          row['key'] = row['id'];
          return row;
        });
        resolve(data);
      },
      (e) => reject(e)
    );
  });
}

export default loadData;
