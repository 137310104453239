import {Alert, Button, Card, ConfigProvider, Divider, Form, Input, message} from "antd";
import Select from "../../components/Select";
import {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {WSContext} from "../../providers/WSProvider";
import {replaceMessage} from "../../utils/formatters";

const { ConfigContext } = ConfigProvider;

export default function Account() {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const wsContext = useContext(WSContext);
  const [form] = Form.useForm();
  const bioInitial = {
    first_name: authContext.user.first_name,
    last_name: authContext.user.last_name,
  };

  const validateMessages = {
    required: configContext.locale.Forms.errors.required,
  };

  const updateBio = (data) => {
    if (data.first_name === authContext.user.first_name && data.last_name === authContext.user.last_name) {
      message.warning(configContext.locale.Settings.account.no_bio_updates);
      return null;
    }

    wsContext.sendRequest(
      wsContext.prepareRequest('auth.update_bio', data),
    ).then(
      () => message.success(configContext.locale.Settings.account.bio_updated, 2).then(
        () => window.location.reload(),
      ),
      error => {
        console.error(error);
        message.error(error.message);
      }
    )
  }

  const updateLanguage = (language) => {
    wsContext.sendRequest(
      wsContext.prepareRequest('settings.update_language', {language})
    ).then(
      () => message.success(configContext.locale.Settings.account.language_updated, 2).then(
        () => {
          localStorage.setItem('language', language);
          window.location.reload();
        },
      ),
      error => {
        console.error(error);
        message.error(error.message);
      }
    )
  }

  const sendVerification = () => {
    wsContext.sendRequest(
      wsContext.prepareRequest('email.send_email_verification'),
    ).then(
      data => {
        if (data['status'] === 'success') {
          const email = data['email'];
          message.info(replaceMessage(configContext.locale.Settings.account.message_sent, {email}));
        }
      },
    )
  }

  return (
    <Card
      title={configContext.locale.Settings.sections.account}
      style={{ maxWidth: 600, width: 600 }}
    >
      <div>
        <h4>{configContext.locale.Settings.account.change_bio}</h4>
        <Form
          labelCol={{ span: 6 }}
          form={form}
          onFinish={updateBio}
          initialValues={bioInitial}
          validateMessages={validateMessages}
        >
          <Form.Item
            label={configContext.locale.Settings.form.first_name}
            name='first_name'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={configContext.locale.Settings.form.last_name}
            name='last_name'
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}
          >
            {({isFieldsTouched}) =>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!isFieldsTouched()}
                >
                  {configContext.locale.Button.update}
                </Button>
              </div>
            }
          </Form.Item>
        </Form>
        <Divider />
        <h4>{configContext.locale.Settings.account.email_verification}</h4>
        {authContext.settings.email_verified ? (
          <Alert message={configContext.locale.Settings.account.email_verified} type="success" showIcon />
        ) : (
          <Button onClick={sendVerification}>
            {configContext.locale.Settings.account.verify_email}
          </Button>
        )}
        <Divider />
        <h4>{configContext.locale.Settings.account.change_language}</h4>
        <Form labelCol={{ span: 6 }}>
          <Form.Item label={configContext.locale.Settings.form.pref_language} name='language'>
            <Select
              data={authContext.settings.constants.available_languages.map(lang => {
                return {
                  value: lang,
                  label: {en_GB: 'English', uk_UA: 'Українська'}[lang] || 'English',
                }
              })}
              selected={authContext.settings.language}
              allowClear={false}
              onChange={updateLanguage}
            />
          </Form.Item>
        </Form>
      </div>
    </Card>
  )
}
